import React from "react";
import { useState, useReducer, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom, requestToServer } from "../utils/appUtils";
import LogList from "./LogList";

//const ROUTE_URL = "/log_lists";
let ROUTE_URL = "/sys_users";

const LogLists = () => {
  //const [serverData, setServerData] = useState(null);
  //const { showErrorPage, messageBox } = useOutletContext();

//   useEffect(() => {
//     requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
//   }, []);

//   if (serverData === null)
//     return <MainArea loading />;

  
  const { userInfo } = useOutletContext();
  console.log(userInfo);


  return (
    <MainArea>
      <MainAreaHeader caption="Протокол действий" />
      <MainAreaBody containerClass="">
          <LogList url={ROUTE_URL + "/-1?query=log_list"} type="all" />
      </MainAreaBody>
    </MainArea >
  );
}

export default LogLists;
