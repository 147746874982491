import CardInfo from './cardInfo';
import CardLogins from './cardLogins';
import CardTopOperators from './cardTopOperators';
import CardLastOrders from './cardLastOrders';

const ROUTE_URL = "/dashboard/";
const Home = (props) => {

  return (
    <div className="container-fluid px-3 py-1 dashboard">
      <div className="row g-3">
        {/* <div className="col-md-12 col-lg-12 col-xl-6">
          { <CardInfo url={ROUTE_URL + "info"} /> }
        </div> */}
        <div className="col-md-12 col-lg-12 col-xl-6">
          <CardLogins url={ROUTE_URL + "lastLogins"} />
        </div>
        {/* <div className="col-md-12 col-lg-12 col-xl-6">
          <CardTopOperators url={ROUTE_URL + "operators"} />
        </div> */}
        {/* <div className="col-md-12 col-lg-12 col-xl-6">
          <CardLastOrders url={ROUTE_URL + "lastOrders"} />
        </div> */}
      </div>
    </div>
  );
}

export default Home;

