import { useState, useReducer, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom, requestToServer } from "../utils/appUtils";
import TableDT from "../UI/TableDT";
//import { reducerModalWindow } from "../UI/ModalWindow";
//import Product from "./Product";

const ROUTE_URL = "/map_objects";

let dtTable = {
  Api: null,
  getTableOptions: (crud) => {
    const dtOptions = {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
      order: [[1, "asc"]],
      ajax: { type: "GET", url: ROUTE_URL },
      dom: getDataTableDom("lfip", 2, 1),
      columns: [
        { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
        { data: "id", title: "Код", className: "dt-body-right", },
        { data: "parent_id", title: "Код родительского объекта", className: "dt-body-right", },
        { data: "address", title: "Адрес", render: DataTable.render.dataToAnchor("btnShowEdit"), },
        { data: "name", title: "Наименование", render: DataTable.render.text(), },
        { data: "map_object_type_name", title: "Тип", render: DataTable.render.text(), },
        { data: "path", title: "Путь", render: DataTable.render.text(), },
        { data: "floors", title: "Этажей в здании", render: DataTable.render.text(), },
        { data: "floor", title: "Объект на этаже №", render: DataTable.render.text(), },
        { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
        { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
      ],
    };
    if ((crud & 1) === 0) {
      dtOptions.order = [[0, "asc"]];
      dtOptions.columns.splice(0, 1);
      dtOptions.dom = getDataTableDom("lfip", 1, 1);
    }
    return dtOptions;
  }
};

function MapObjects(props) {
  const [serverData, setServerData] = useState(null);
  const { showErrorPage, messageBox } = useOutletContext();
  const navigate = useNavigate();
  //const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false, className: "modal-lg", });

  const handleAddRow = () => {
    //dispatchModalWindow({ type: 'add' });
    navigate("./create");
  };

  // const handleCloseModalWindow = (result) => {
  //   if (result.modalResult === 'mrOk') dtTable.Api.ajax.reload(null, false);
  //   dispatchModalWindow({ type: 'destroy' });
  // };

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlDelete = ROUTE_URL + "/" + data.id;
      messageBox({
        show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
          e.preventDefault();
          messageBox({ show: false, mbOk: null });
          requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
        }
      });
    });
    $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      //dispatchModalWindow({ type: 'edit', id: data.id });
      let urlStr = "./" + data.id;
      navigate(urlStr);
    });
    $(DOM).on('click', 'tbody button[name="btnShowContracts"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlStr = "./" + data.id + "/contracts";
      navigate(urlStr);
    });
  }

  useEffect(() => {
    requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
  }, []);

  if (serverData === null)
    return <MainArea loading />;

  return (
      <MainArea>
        <MainAreaHeader caption="Объекты на карте" />
        <MainAreaBody>
          {
            (serverData.crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
          }
          <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
        </MainAreaBody>
      </MainArea>
  );
}

export default MapObjects;
