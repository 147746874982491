import { useEffect, useState } from "react";
import { useRef } from 'react';
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import moment from "moment/moment";
import $ from "jquery";
import DataTable from 'datatables.net'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { requestToServer, formatServerErorrs } from "../utils/appUtils";
import { Input, Select, TextArea, ButtonSpinner } from '../UI/FormElements';
import ObjMap from './ObjMap';
// import OperatorUsers from "./OperatorUsers";

let ROUTE_URL = "/map_objects";
let initialData = {
  data: { parent_id: "", address: "", name: "", map_object_type_id: "", floors: "", floor: ""
    , geog_polygon: "", geog_point: "", geog_point_parent: "", path: "" }
};

const MapObject = (props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(initialData.data);
  const [serverData, setServerData] = useState(null);
  const { showErrorPage } = useOutletContext();
  const navigate = useNavigate();

//   const inputLogoRef = useRef(null);
//   const imgLogoRef = useRef(null);

  let currentURL = new URL(window.location.href);
  let urlParams = useParams();
  let params = { isNew: urlParams.id === "create", id: urlParams.id }

  const afterLoadData = (response) => {
    if (params.isNew) {
      setData(initialData.data);
      setServerData(response);
    }
    else {
      let newData = {};

    //   console.log('initialData.data');
    //   console.log(initialData.data);

    //   console.log('response.data');
    //   console.log(response.data);

      for (let key in initialData.data) {
        if (key in response.data) {
          if (response.data[key] === null)
            newData[key] = "";
        //   else if (initialData.format[key] === "date")
        //     newData[key] = response.data[key].substring(0, 10);
          else
            newData[key] = response.data[key];
        }
        else newData[key] = initialData.data[key];
      }

    //   console.log('newData2');
    //   console.log(newData);

      setData(newData);
      setServerData(response);
    }
  }

  const handleClose = (event) => {
    if (loading) return;
    navigate(currentURL.searchParams.has('created') ? -2 : -1);
  }
  const handleChange = (event) => {
    if (loading) return;
    setData({ ...data, [event.target.name]: event.target.value });
  }
  const handleTabSelect = (event) => {
     DataTable.tables({ visible: true, api: true }).columns.adjust();
  }

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setErrors({});
    setLoading(true);
    let formData = new FormData();
    for (let key in data)
      formData.append(key, data[key]);
    requestToServer(ROUTE_URL + (params.isNew ? "" : "/" + params.id), { method: params.isNew ? 'POST' : 'PATCH', body: formData },
      (response) => { setLoading(false); if (params.isNew) { setServerData(null); navigate(ROUTE_URL + "/" + response.id + "?created=1"); } else handleClose(null); },
      (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }
//   const handleLogoClick = () => {
//     inputLogoRef.current.click();
//   }
//   const handleLogoChange = (event) => {
//     imgLogoRef.current.src = URL.createObjectURL(event.target.files[0]);
//     setData({ ...data, ["operator_picture"]: event.target.files[0] });
//   }

  useEffect(() => {
    requestToServer(ROUTE_URL + (params.isNew ? "/create" : "/" + params.id), { method: 'GET' }, afterLoadData, showErrorPage, true);
  }, [urlParams.id]);

  if (serverData === null)
    return <MainArea loading />;

  let caption = "Просмотр", isCanSave = false;
  if (params.isNew) {
    caption = "Добавление";
    isCanSave = true;
  }
  else if ((serverData.crud & 2) != 0) {
    caption = "Изменение";
    isCanSave = true;
  }

  let elementProps = { data, errors, onChange: handleChange };
  let operatorInfo = (
    <div className="container-xxl">
      <form onSubmit={handleSubmitForm} autoComplete="off">
        <fieldset disabled={loading}>
          <div className="row form-gutter pb-3">
          
            <div className="col-md-12">
              <Input label="Путь" name="path" {...elementProps} />
            </div>
            <div className="col-md-12">
              <TextArea label="Адрес" name="address" {...elementProps} />
            </div>
            <div className="col-md-3">
              <Input label="Наименование" name="name" {...elementProps} required />
            </div>
            <div className="col-md-3">
                <Select label="Тип объекта" name="map_object_type_id" {...elementProps} selectOptions={serverData.map_object_types} required />
            </div>
            <div className="col-md-3">
              <Input label="parent_id" name="parent_id" {...elementProps} />
            </div>
            <div className="col-md-3">
              <Input label="Этажей в здании" name="floors" {...elementProps}  />
            </div>
            <div classNa--me="col-md-3">
              <Input label="Объект на этаже №" name="floor" {...elementProps}  />
            </div>
            <div className="col-md-3">
                <Input label="Point" name="geog_point" {...elementProps}  />
            </div>
            <div className="col-md-3">
                <Input label="Parent Point" name="geog_point_parent" {...elementProps}  />
            </div>
            <div className="col-md-3">
            </div>
            <div className="col-md-12">
                <TextArea label="Полигон" name="geog_polygon" {...elementProps} required />
            </div>
            

          </div>
          {errors._message_ && <div className="form-error mb-2">{errors._message_}</div>}
          <div>
            {isCanSave && <ButtonSpinner className="btn-sm me-2" loading={loading}>Сохранить</ButtonSpinner>}
            <button type="button" className="btn btn-secondary btn-sm btn-our" onClick={handleClose}>Закрыть</button>
          </div>
        </fieldset>
      </form>
    </div>
  );

  let cardHeader = (
    <MainAreaHeader className="card-header py-1 px-2">
      <div className="d-flex align-items-stretch" >
        {/* <div className="d-flex align-items-center flex-grow-1">
          <div className="me-2" role="button" onClick={handleLogoClick}
            style={{ height: "64px", border: "0.5px solid rgba(0, 0, 0, 0.2)" }}>
            <img ref={imgLogoRef} alt="load logo" width="100%" height="100%" src={process.env.REACT_APP_URL + "/" + (data.logo ? data.logo : "images/load.png")} />
            <input ref={inputLogoRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleLogoChange} />
          </div>
          <div>
            <div className="fw-bold" id="edUserName" style={{ fontSize: "0.9rem" }}>{data.name_legal}</div>
            <div>
              <small className="text-light-new">Создан: </small><small>
                {serverData.data.created_at ? moment.utc(serverData.data.created_at).local().format("DD.MM.YYYY HH:mm:ss") : serverData.data.created_at}
              </small><br />
              <small className="text-light-new">Последний вход: </small><small>
                {serverData.last_login ? moment.utc(serverData.last_login).local().format("DD.MM.YYYY HH:mm:ss") : serverData.last_login}
              </small>
            </div>
          </div>
        </div> */}
        <div>
          <button className="btn btn-close ms-2" onClick={handleClose}></button>
        </div>
      </div>
    </MainAreaHeader >
  );

  // console.log('serverData.data');
  // console.log(serverData.data);

  return (
    <MainArea>
      {cardHeader}
      <MainAreaBody>
        <Tab.Container defaultActiveKey="objectInfo">
          <Nav as="ul" className="mb-2 our-nav no-gutters nav nav-fill">
            <Nav.Item as="li">
              <Nav.Link eventKey="objectInfo">Информация</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="objectMap">Объект на карте</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="objectInfo">
              {operatorInfo}
            </Tab.Pane>
            {!params.isNew && (
              <Tab.Pane eventKey="objectMap" onEntering={handleTabSelect}>
                <ObjMap geog_point_xy={serverData.data.geog_point_parent_xy} geog_polygon={serverData.data.geog_polygon_json}/>
                </Tab.Pane>)
            }
          </Tab.Content>
        </Tab.Container>
      </MainAreaBody>
    </MainArea>
  );
}

export default MapObject;