import { ModalWindow } from "../UI/ModalWindow";
import { Input } from '../UI/FormElements'

const initialData = { name: "", description: "" };

function SimpleSpr({ url, params, handleClose }) {

  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = url;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = url + "/" + params.id;
    urlSubmit = urlGet;
  }
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <SimpleSprFields />
    </ModalWindow>
  );
}

const SimpleSprFields = ({ dopParams, ...elementProps }) => {
  return (
    <div className="row form-gutter">
      <div className="col-12">
        <Input label="Наименование" name="name" {...elementProps} required autoFocus />
      </div>
      {/* <div className="col-12">
        <Input label="Описание роли" name="description" {...elementProps} required />
      </div> */}
    </div>
  )
}
export default SimpleSpr;
