import { useState } from 'react';
import CardHeaderDashboard from "./cardHeaderDashboard";
import LoginHistory from '../access/LoginHistory';

let dtLogins = null;
const returnTable = (tableApi) => {
  dtLogins = tableApi;
}

const CardLogins = (props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    event.target.blur();
    dtLogins.ajax.reload();
  }

  return (
    <div className="card h-100">
      <CardHeaderDashboard loading={loading} onClick={handleClick}>
        История входов
      </CardHeaderDashboard>
      <div className="card-body pt-0">
        <LoginHistory returnTable={returnTable} setLoading={setLoading} type="dashboard" url={props.url} />
      </div>
    </div>
  );
}

export default CardLogins;