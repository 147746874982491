import React, { useState } from 'react';
//import './App.css';
import Map from "../Map/Map";
import Layers from "../Map/Layers/Layers";
import TileLayer from "../Map/Layers/TileLayer";
import VectorLayer from "../Map/Layers/VectorLayer";
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { osm, vector } from "../Map/Source";
import { fromLonLat, get } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import Controls from "../Map/Controls/Controls";
import FullScreenControl from "../Map/Controls/FullScreenControl";

import mapConfig from "../Map/config.json";

let styles = {
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'blue',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
};

let geojsonObject = mapConfig.geojsonObject;
//const geojsonObject2 = mapConfig.geojsonObject2;

const ObjMap = ({ geog_point_xy, geog_polygon }) => {
  // const [center, setCenter] = useState([-94.9065, 38.9884]);
  const [center, setCenter] = useState(JSON.parse(geog_point_xy));
  const [zoom, setZoom] = useState(19);
  const [showLayer1, setShowLayer1] = useState(true);
  const [showLayer2, setShowLayer2] = useState(true);

  geojsonObject = JSON.parse(geog_polygon);

// console.log('center');
// console.log(center);
// console.log('geog_point_xy');
// console.log(geog_point_xy);
// console.log('geog_polygon');
// console.log(geog_polygon);
// console.log('geog_point_xy  JSON.parse');
// console.log(JSON.parse(geog_point_xy));

return (
  <div>
    <Map center={fromLonLat(center)} zoom={zoom}>
      <Layers>
        <TileLayer
          source={osm()}
          zIndex={0}
        />
        {showLayer1 && (
          <VectorLayer
            source={vector({ features: new GeoJSON().readFeatures(geojsonObject, { featureProjection: get('EPSG:3857') }) })}
            style={styles.MultiPolygon}
          />
        )}
        {/* {showLayer2 && (
          <VectorLayer
            source={vector({ features: new GeoJSON().readFeatures(geojsonObject2, { featureProjection: get('EPSG:3857') }) })}
            style={styles.MultiPolygon}
          />
        )} */}
      </Layers>
      <Controls>
        <FullScreenControl />
      </Controls>
    </Map>
    <div>
      <input
        type="checkbox"
        checked={showLayer1}
        onChange={event => setShowLayer1(event.target.checked)}
      /> Показать объект
      {/* Johnson County */}
    </div>
    {/* <div>
      <input
        type="checkbox"
        checked={showLayer2}
        onChange={event => setShowLayer2(event.target.checked)}
      /> Wyandotte County</div> */}
    </div>
  );
}
export default ObjMap;