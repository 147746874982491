const createElement = (type, props) => {
  let { label, data, errors, ...params } = props;
  let error = (typeof errors === "object" && !Array.isArray(errors)) ? errors[params.name] : errors;
  if (!("value" in params) && data)
    params.value = data[params.name];
  params.className = `form-${type == "select" ? "select" : "control"} ` + (params.className ? params.className : "") + (error ? " is-invalid" : "");

  let element;
  if (type == "input")
    element = (<input {...params} />);
  else if (type == "textarea")
    element = (<textarea {...params}></textarea>);
  else if (type == "select") {
    delete params.selectOptions;
    element = (
      <select {...params}>
        {
          props.selectOptions.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))
        }
      </select>
    );
  }
  return (
    <>
      {label && <label className="form-label">{label}</label>}
      {element}
      <div className="invalid-feedback">{error}</div>
    </>
  );
}

export const Input = (props) => {
  return createElement('input', props);
}

export const TextArea = (props) => {
  return createElement('textarea', props);
}

export const Select = (props) => {
  return createElement('select', props);
}

export const ButtonSpinner = ({ loading, className, children, ...params }) => {
  let btnProps = { type: "submit", className: "btn btn-primary btn-our" };
  if (className)
    btnProps.className = btnProps.className + " " + className;
  if (params)
    btnProps = { ...btnProps, ...params };
  return (
    <button {...btnProps}>
      {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
      {children}
    </button>);
}