// import { Routes, Route } from "react-router-dom";
import { createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";

import "./components/utils/DataTablesUtils";
import Layout from "./components/layout/Layout.jsx";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ErrorPage from "./components/ErrorPage.js";
//import Game from "./components/game/Game";
import Home from "./components/home/Home";
import Login from "./components/auth/Login";
import ChangePassword from "./components/auth/ChangePassword";

import Rules from "./components/access/Rules.jsx";
import Roles from "./components/access/Roles.jsx";
import SettingAccess from "./components/access/SettingAccess.jsx";
import SysUsers from "./components/access/SysUsers.jsx";
import SysUser from "./components/access/SysUser.jsx";
import LogLists from "./components/access/LogLists.jsx";

// import Operators from "./components/operator/Operators.jsx";
// import Operator from "./components/operator/Operator";
// import Documents from "./components/others/Documents.jsx";
// import Products from "./components/others/Products.jsx";
// import Product from "./components/others/Product.jsx";
import MapObjects from "./components/others/MapObjects.jsx";
import MapObject from "./components/others/MapObject.jsx";
import SimpleSprs from "./components/others/SimpleSprs.jsx";

// import AllOperatorUsers from "./components/operator/AllOperatorUsers";

const App = createBrowserRouter(
   createRoutesFromElements(
      <>
         <Route path="/login" element={<Login />} />
         <Route path="/forgot_password" element={<ForgotPassword />} />
         <Route path="/reset_password/:token/:login" element={<ResetPassword />} />
         <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
            <Route index element={<Home />} />
            <Route path="changepassword" element={<ChangePassword />} errorElement={<ErrorPage />} />
            <Route path="rules" element={<Rules />} errorElement={<ErrorPage />} />
            <Route path="rules/:id/roles" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles" element={<Roles />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/rules" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/sys_users" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="sys_users" element={<SysUsers />} errorElement={<ErrorPage />} />
            <Route path="sys_users/:id" element={<SysUser />} errorElement={<ErrorPage />} />
            <Route path="log_lists" element={<LogLists />} errorElement={<ErrorPage />} />

            {/* <Route path="operators" element={<Operators />} errorElement={<ErrorPage />} /> */}
            {/* <Route path="operators/:id" element={<Operator />} errorElement={<ErrorPage />} /> */}
            {/* <Route path="users_operators" element={<AllOperatorUsers />} errorElement={<ErrorPage />} />
            <Route path="documents" element={<Documents />} errorElement={<ErrorPage />} />
            <Route path="products" element={<Products />} errorElement={<ErrorPage />} />
            <Route path="products/:id" element={<Product />} errorElement={<ErrorPage />} /> */}

            {/* <Route path="product_rooms" element={<SimpleSprs caption="Номера" url="/product_rooms" />} errorElement={<ErrorPage />} />
            <Route path="product_foods" element={<SimpleSprs caption="Питание" url="/product_foods" />} errorElement={<ErrorPage />} />
            <Route path="product_services" element={<SimpleSprs caption="Услуги в отеле" url="/product_services" />} errorElement={<ErrorPage />} />
            <Route path="product_durations" element={<SimpleSprs caption="Продолжительность тура" url="/product_durations" />} errorElement={<ErrorPage />} />
            <Route path="product_genres" element={<SimpleSprs caption="Жанр тура" url="/product_genres" />} errorElement={<ErrorPage />} />
            <Route path="product_tour_categories" element={<SimpleSprs caption="Категория тура" url="/product_tour_categories" />} errorElement={<ErrorPage />} />
            <Route path="product_service_classes" element={<SimpleSprs caption="Класс обслуживания" url="/product_service_classes" />} errorElement={<ErrorPage />} />
            <Route path="document_types" element={<SimpleSprs caption="Типы документов" url="/document_types" />} errorElement={<ErrorPage />} /> */}

            <Route path="map_objects" element={<MapObjects />} errorElement={<ErrorPage />} />
            <Route path="map_objects/:id" element={<MapObject />} errorElement={<ErrorPage />} /> 
            <Route path="map_object_types" element={<SimpleSprs caption="Типы объектов" url="/map_object_types" />} errorElement={<ErrorPage />} />

            {/* <Route path="game" element={<Game />} errorElement={<ErrorPage />} /> */}
            <Route path="*" element={<ErrorPage errorCode="404" />} />
         </Route>
      </>
   )
   // ,{ basename: "/admin", }
);

export default App;
