const MainArea = (props) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className={props.loading ? "card align-items-center justify-content-center" : "card"} style={{ minHeight: "var(--clientheight)" }}>
            {
              props.loading ? <div className="loader"></div> : props.children
            }
          </div>
        </div>
      </div>
    </div >
  );
}

//props.loading ? <div><i className="mr-1 fa fa-spinner fa-spin fa-2x fa-fw"></i><span>Подождите...</span></div> : props.children
// const MainAreaCard = (props) => {
//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col">
//           <div className="card" style={{ minHeight: "var(--clientheight)" }}>
//             {props.children}
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// }

const MainAreaHeader = (props) => {
  return (
    <div className={`card-header ${props.className ? props.className : ''}`}>
      {props.caption ? (<h4 className="card-title">{props.caption}</h4>) : props.children}
    </div >
  );
}

const MainAreaBody = (props) => {
  return (
    <div className={`card-body ${props.className ? props.className : ''}`}>
      {/* <div className={`card-body ${props.className ? props.className : ''}`}> */}
      {props.containerClass ? (<div className={props.containerClass}>{props.children}</div>) : props.children}
    </div >
  );
}

export { MainArea, /*MainAreaCard,*/ MainAreaHeader, MainAreaBody };